import React, { Component } from 'react';
import { useState, useEffect } from 'react';

import {
    useAuth,
    useUser
} from "@clerk/clerk-react";

let AUDIO_API_URL = "https://funquiz.app/modular2/voice-replicator-1"
const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev
let MAIN_API_NAME = "nl_img_editor_ai_1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();
  
  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function AudioConverter() {
    const emailAddr = UserEmailAddress();
    const { getToken } = useAuth();
    const [selectedFile, setSelectedFile] = useState(null);
    // const [wavData, setWavData] = useState(null);
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [textOutput, setTextOutput] = useState('');
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      console.log("hey wavy")
      if (file) {
        console.log("convert to wav")
        // Call the convertToWav function
        // convertToWav(file);
        setSelectedFile(event.target.files[0]);
      }
    };
  
    // const convertToWav = (audioFile) => {
    //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    //   const reader = new FileReader();

    //   reader.onload = async (e) => {
    //     const audioData = e.target.result;
    //     const decodedData = await audioContext.decodeAudioData(audioData);

    //     // Create a WAV buffer
    //     const wavBuffer = audioContext.createBuffer(1, decodedData.length, audioContext.sampleRate);
    //     wavBuffer.copyToChannel(decodedData.getChannelData(0), 0);

    //     // Store the WAV data in state
    //     console.log("we got here")
    //     setWavData(wavBuffer);
    //   };

    //   reader.readAsArrayBuffer(audioFile);
    // };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // Set textOutput to 'Loading...' while processing
      setTextOutput('Loading...');
  
      try {
        const formData = new FormData();
  
        // Append the WAV data to the FormData
        // const wavBlob = await new Promise((resolve) => {
        //   console.log("wavData")
        //   console.log(wavData);
          
        //   wavData.toBlob((blob) => {
        //     resolve(blob);
        //   }, 'audio/wav');
        // });

        // const wavBlob = new Blob([wavData], { type: 'audio/wav' })
        // console.log("wavBlob");
        // console.log(wavBlob);
  
        formData.append('sound1', selectedFile);//wavBlob, 'audio.wav');
        formData.append('text', text1);
        formData.append('language', text2);
        formData.append('email', emailAddr);
        formData.append('env', environment);
        formData.append('api_name',MAIN_API_NAME)
  
        const myToken = await getToken();
        // Send the FormData to the Flask API using fetch
        const response = await fetch(AUDIO_API_URL, {
          mode: 'cors',
          method: 'POST',
          body: formData,
          headers: {
            'Authorization': `Bearer ${myToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          // Handle the API response and set the textOutput
          setTextOutput(data.textOutput);
        } else {
          // Handle errors or set an error message in textOutput
          setTextOutput('An error occurred.');
        }
      } catch (error) {
        console.error('Error sending data to API:', error);
        // Set an error message in textOutput if needed
        setTextOutput('An error occurred.');
      }
    };
  
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <input type="file" accept="audio/*" onChange={handleFileChange} />
          <input type="text" name="text1" placeholder="Text 1" value={text1} onChange={(e) => setText1(e.target.value)} />
          <input type="text" name="text2" placeholder="Text 2" value={text2} onChange={(e) => setText2(e.target.value)} />
          <button type="submit">Convert and Send</button>
        </form>
  
        {/* Display the textOutput */}
        <div>{textOutput}</div>
      </div>
    );
  }
  
  export default AudioConverter;